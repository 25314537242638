import lowerdev from '@/service/lowerdev';
import { env } from 'md-base-tools/env';
import { mapState, mapMutations } from 'vuex';
import ClipboardJS from 'clipboard';
import { mdToast } from '@/utils/tools';
export default {
  name: 'share-box',
  data() {
    return {
      copyBtn: null,
      shareLink: {
        weibo: 'https://service.weibo.com/share/share.php',
        qq: 'https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey'
      }
    };
  },
  computed: {
    ...mapState({
      mall_share: state => state.share
    }),
    env() {
      return env;
    }
  },
  watch: {
    'mall_share.share_info.msg_des': {
      handler(newVal) {
        if (newVal) {
          const target = this.$refs.copyUrl;
          const copyboard = new ClipboardJS(target, {
            text(target) {
              return target.getAttribute('copyData');
            }
          });
          copyboard.on('success', () => {
            mdToast('复制成功');
          });
        }
      }
    },
    'mall_share.showShare': {
      handler(newVal) {
        if (newVal) {
          document.body.style.overflowY = 'hidden';
        } else {
          document.body.style.overflowY = 'auto';
        }
      }
    }
  },
  mounted() {
    // eslint-disable-next-line new-cap
    this.copyBtn = new ClipboardJS(this.$refs.copyUrl);
  },
  methods: {
    ...mapMutations(['changeShareStatus']),
    onShare() {
      this.changeShareStatus({
        obj: 'showShare'
      });
    },
    share_weibo() {
      const weiboShareData = {
        title: encodeURIComponent(this.mall_share.share_info.weibo_des),
        appkey: 940961422
      };
      lowerdev.openWin(this.shareLink.weibo + this.encodeData(weiboShareData));
    },
    share_qq() {
      const qzoneShareData = {
        url: encodeURIComponent(this.mall_share.share_info.qzone_share_url),
        title: this.mall_share.share_info.qq_title,
        summary: this.mall_share.share_info.qq_des,
        desc: '',
        pics: this.mall_share.share_info.img
      };
      lowerdev.openWin(this.shareLink.qq + this.encodeData(qzoneShareData));
    },
    share_report() {
      mdToast('请前往APP进行举报');
    },
    encodeData(obj) {
      const paras = [];
      if (obj && Object.keys(obj).length) {
        Object.keys(obj).forEach(function (key) {
          paras.push(key + '=' + obj[key]);
        });
      }
      const props = paras.length ? paras.join('&') : '';
      return '?' + props;
    }
  }
};