import router from '@/router';
import domain from "@/service/domain";
import redirect from '@/service/redirect';
import { env } from "md-base-tools/env";
import { getShareInfo, getProInviteCpList, FrontGetUserInfo, checkInviteCp } from "@api/index";
import ShareBox from "@@/common/ShareBox.vue";
import { mapState, mapMutations } from 'vuex';
import { GoBack } from "@/utils/tools";
import Poster from "@@/activity/recommondPoster.vue";
import { QuickCopy } from '@/utils/tools';
export default {
  components: {
    ShareBox,
    Poster
  },
  computed: {
    ...mapState({
      userId: state => state.user.userId
    })
  },
  data() {
    return {
      env: env,
      isFix: 0,
      inviteList: [],
      shareInfo: {},
      showShare: false,
      showImg: false,
      imgSrc: '',
      cpInfo: {},
      //CP信息
      showShareBtn: false,
      // 是否显示分享按钮
      // loading: false,

      timeOutEvent: null,
      isLongPress: false,
      systemVersion: '',
      // app版本
      clipboard: null
    };
  },
  async mounted() {
    document.title = '推荐有礼';
    if (!this.userId) {
      return redirect.to('login');
    }

    // eslint-disable-next-line no-undef
    graft.app({
      functionName: "onGetUserInfo",
      functionParams: {},
      callBack: e => {
        this.systemVersion = e.data.build;
      }
    });
    this.getList();
    this.checkLimits();
    if (env.isWeixin() || env.isWeibo()) {
      await this.getShareDetail();
    }
  },
  methods: {
    ...mapMutations(['changeShareStatus', 'currentShareUpdate']),
    // 校验是否能发起邀请 -- 否则不显示分享按钮
    checkLimits() {
      checkInviteCp().then(res => {
        this.showShareBtn = res.data;
      });
    },
    async getList() {
      const {
        status,
        data
      } = await getProInviteCpList();
      if (status == 0 && Array.isArray(data)) {
        this.inviteList = data;
      } else {
        this.inviteList = [];
      }
    },
    // 是否吸顶
    handleStickyAction(is_fix) {
      this.isFix = is_fix ? 1 : 0;
    },
    // 获取分享内容
    async getShareDetail() {
      await getShareInfo({
        type: 60
      }).then(res => {
        if (res.status == 0) {
          this.shareInfo = res.data;
          this.currentShareUpdate({
            obj: 'share_info',
            value: this.shareInfo
          });
        }
      });
    },
    async getDetail() {
      const {
        data,
        status
      } = await FrontGetUserInfo({
        user_id: this.userId
      });
      if (status == 0) {
        this.cpInfo = data;
      }
    },
    // 更多邀请
    async shareMore() {
      this.sensors.track("InviteClick", {
        invite_type: 'share' // 微信小程序内是’wx‘,其余都是'share'
      });
      if (this.shareInfo && Object.keys(this.shareInfo).length == 0) {
        await this.getShareDetail();
      }
      if (env.isModianIos() || env.isModianAndroid()) {
        let obj = JSON.parse(JSON.stringify(this.shareInfo));
        if (this.systemVersion < '62410251') {
          obj.share_password = '';
        }
        // eslint-disable-next-line no-undef
        graft.app({
          functionName: 'openShareTool',
          functionParams: JSON.parse(JSON.stringify(obj))
        });
      } else if (env.isWeixin() || env.isWeibo()) {
        // 微信或微博内直接复制链接
        QuickCopy(this.shareInfo.sharelink_url, '邀请链接已复制，可分享给好友');
      } else {
        this.changeShareStatus({
          obj: 'showShare'
        });
      }
    },
    // 奖励明细
    goScorePromotion() {
      // eslint-disable-next-line no-undef
      graft.app({
        functionName: "openController",
        functionParams: `${domain.wap}/score/gold/list`,
        webFun: () => {
          router.push({
            path: `/score/gold/list`
          });
        }
      });
    },
    //离开页面
    handleGoBack() {
      GoBack();
    },
    // 生成图片--海报
    async generateImage() {
      this.sensors.track("InviteClick", {
        invite_type: "picture"
      });

      // this.loading = true
      if (this.shareInfo && Object.keys(this.shareInfo).length == 0) {
        await this.getShareDetail();
      }
      if (this.cpInfo && Object.keys(this.cpInfo).length == 0) {
        await this.getDetail();
      }
      this.$refs.poster.generateImage();
    },
    updateImgSrc(img) {
      // this.loading = false
      this.showImg = true;
      this.imgSrc = img;
    },
    closeShowImg() {
      this.showImg = false;
    }
  }
};