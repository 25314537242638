import env from 'md-base-tools/env'
import domain from '@/service/domain'
import lowerdev from '@/service/lowerdev'

const redirect = {
  to: (type, option, redirectUrl) => {
    let host = domain.wap
    let wxhost = ''
    let qqhost = ''
    let props = ''
    const address = encodeURIComponent(redirectUrl || location.href)
    if (typeof option === 'object' && !Array.isArray(option) && Object.keys(option).length) {
      const paras = []
      Object.keys(option).forEach(key => {
        paras.push(key + '=' + option[key])
      })
      props = '&' + paras.join('&')
    }
    if (env.isMobile()) {
      host = domain.wap
    } else {
      host = domain.me
      if (type === 'login') {
        host = domain.login
      }
      if (type === 'home' || type === 'search') {
        host = domain.wap
      }
    }
    switch (type) {
      case 'home':
        host += '/index/index_wb?'
        break
      case 'login':
        props = '_mpos=h_login_wap' + props
        host += '/u/login?'
        wxhost += '/mainPages/login/login?'
        qqhost += '/ucenter/login/index'
        break
      case 'center':
        props = '_mpos=h_user_wap' + props
        host += '/u/detail?'
        wxhost += '/userPages/user/user?'
        break
      case 'order':
        if (env.isMobile()) {
          host += '/user_order/order?'
        } else {
          host += '/order/index?'
        }
        wxhost += '/orderPages/order/order?'
        break
      case 'add_address':
        host += '/main/add_address?'
        wxhost += '/addressPages/add_address/add_address?'
        break
      case 'address_list':
        host += '/main/address_list?'
        wxhost += '/addressPages/address_list/address_list?'
        break
      case 'update_address':
        host += '/main/update_address?'
        break
      case 'search':
        host += '/index/wb_search?'
        wxhost += '/searchPages/search/search?'
        break
      case 'sign_coupon':
        host += '/u/user_sign_coupon?'
        break
    }
    if (env.isWxApp() && wxhost) {
      wxhost = '/pages' + wxhost
      if (type === 'center') {
        props = props.replace('_mpos=h_user_wap&uid', 'user_id')
      }
      if (type === 'unihd') {
        // eslint-disable-next-line
        wx.navigateToMiniProgram({
          appId: 'wx1a3af9f1e4466abc',
          path: option
        })
        return false
      }
      // eslint-disable-next-line
      wx.miniProgram.navigateTo({
        url: wxhost + props + '&redirect_url=' + address
      })
    } else if (env.isToutiao() && wxhost) {
      wxhost = '/pages' + wxhost
      // eslint-disable-next-line
      tt.miniProgram.navigateTo({
        url: wxhost + props + '&redirect_url=' + address
      })
    } else if (sessionStorage.getItem('miniProgram') === 'qq' && qqhost) {
      qqhost = '/pages' + qqhost
      // eslint-disable-next-line
      qq.miniProgram.navigateTo({
        url: qqhost + props + '&redirect_url=' + address
      })
    } else {
      if (type === 'unihd') {
        location.href = '/unihd' + option
        return false
      }
      lowerdev.openWin(host + props + '&redirect_url=' + address)
    }
  }
}

export default redirect
