import { domPixelRatio } from "md-base-tools/env";
import html2canvas from 'html2canvas';
import { mdToast } from '@/utils/tools';
export default {
  props: {
    shareInfo: {
      type: Object
    },
    cpInfo: {
      type: Object
    }
  },
  methods: {
    generateImage() {
      const canvasDom = this.$refs.posterInfo;
      html2canvas(canvasDom, {
        dpi: 300,
        // 解决生产图片模糊
        useCORS: true,
        allowTaint: false,
        scale: domPixelRatio(),
        width: canvasDom.offsetWidth,
        height: 2 * canvasDom.offsetWidth
      }).then(canvas => {
        var img = canvas.toDataURL("image/png");
        this.$emit('updateImgSrc', img);

        // eslint-disable-next-line no-undef
        graft.app({
          functionName: "saveImage",
          functionParams: img,
          callBack: e => {
            if (e.errorCode == 0) {
              mdToast('保存成功');
            }
          }
        });
      }).catch(() => {
        // this.$toast.clear()
      });
    }
  }
};